<template>
  <span class="flex rounded-md justify-center relative">
    <input
      v-bind:value="localDate"
      type="text"
      class="w-10/12 rounded-l-md outline-none text-base text-center"
      :id="'dp' + instanceId"
      data-date-format="dd-mm-yyyy"
      data-language="da"
      placeholder="Select date"
      :disabled="disabled"
    />
    <span
      v-if="!small"
      class="flex items-center justify-center w-2/12 rounded-r-md text-white"
      :class="[disabled ? 'bg-green' : 'bg-yellow']"
    >
      <i class="fas fa-calendar-day"></i>
    </span>
  </span>
</template>

<script>
import { onMounted, computed, getCurrentInstance } from "vue";
import jQuery from "jquery";
import "jquery-ui-dist/jquery-ui.js";
import "jquery-ui-dist/jquery-ui.min.css";
const $j = jQuery.noConflict();

export default {
  name: "DatePicker",
  emits: ["update:modelValue"],
  props: {
    modelValue: Number,
    disabled: Boolean,
    min: Number,
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const instanceId = getCurrentInstance().uid;

    const functions = {
      stringToTime: dateString => {
        const year = dateString.substring(6, 10);
        const month = dateString.substring(3, 5);
        const date = dateString.substring(0, 2);
        const dateObject = new Date(year, month - 1, date);
        return dateObject.getTime();
      },

      timeToString: dateObject => {
        let year = dateObject.getFullYear();
        let month = (dateObject.getMonth() + 1).toString();
        let date = dateObject.getDate().toString();
        props.small ? (year = year.toString().slice(2, 5)) : null;
        month.length === 1 ? (month = "0" + month) : month;
        date.length === 1 ? (date = "0" + date) : date;
        const formattetString = date + "-" + month + "-" + year;
        return formattetString;
      }
    };

    const state = {
      localDate: computed(() =>
        functions.timeToString(new Date(props.modelValue))
      )
    };

    onMounted(() => {
      /*
      const mindate = new Date();
       mindate.setDate(mindate.getDate() - 8);
      const maxdate = new Date();
      maxdate.setDate(maxdate.getDate() - 1); */
      $j("#dp" + instanceId)
        .datepicker({
          language: "da",
          range: false,
          dateFormat: "dd-mm-yy",
          multipleDates: false,
          multipleDatesSeparator: " - "
        })
        .on("change", function(ev) {
          emit("update:modelValue", functions.stringToTime($j(this).val()));
        });
    });

    return { ...state, ...functions, instanceId };
  }
};
</script>

<style scoped></style>
